import { render, staticRenderFns } from "./project-management.vue?vue&type=template&id=ed5c215c&scoped=true&"
import script from "./project-management.vue?vue&type=script&lang=js&"
export * from "./project-management.vue?vue&type=script&lang=js&"
import style0 from "vue-multiselect/dist/vue-multiselect.min.css?vue&type=style&index=0&lang=css&"
import style1 from "./project-management.vue?vue&type=style&index=1&id=ed5c215c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed5c215c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VBreadcrumbs,VBtn,VCard,VContainer,VDivider,VFlex,VIcon,VLayout,VList,VRow,VTextField})

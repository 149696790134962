<template>
  <div id="project-management">
    <full-loader v-show="loading"></full-loader>
    <v-container fluid class="wrapper">
      <!-- breadcrum and previous router -->
      <div class="breadcrumb-header">
        <v-btn text small icon color="primary" @click="$router.go(-1)">
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-breadcrumbs :items="breadcrumbItems" divider="/"></v-breadcrumbs>
      </div>
      <!-- label and nav button -->
      <div class="header">
        <h3>Project Management:</h3>
      </div>

      <v-layout warp>
        <v-flex sm12 md4>
          <!-- project list  -->
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
            <v-container>
              <div
                style="min-height: 30px; text-align: center; font-weight: 700"
              >
                Projects
              </div>
              <v-divider></v-divider>
              <v-text-field
                clearable
                flat
                solo
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                v-model="search_key"
                @input="search"
              ></v-text-field>
              <v-divider></v-divider>
              <v-list style="height: 70vh; overflow: auto; position: relative">
                <div
                  class="container list-wrapper"
                  style="cursor: pointer"
                  @click="getSectionByZone(zone)"
                  :class="{
                    active:
                      selectedZoneData && selectedZoneData._id == zone._id,
                  }"
                  v-for="(zone, i) in temp_zones"
                  :key="i"
                >
                  {{ zone.name }}
                </div>
              </v-list>
              <div v-if="temp_zones && temp_zones.length == 0" class="no-data">
                No Zones
              </div>
            </v-container>
          </v-card>
        </v-flex>

        <!-- section list -->
        <v-flex sm12 md4>
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
            <v-container>
              <v-row class="right-wrapper">
                <v-container>
                  <div
                    style="
                      min-height: 30px;
                      text-align: center;
                      font-weight: 700;
                    "
                  >
                    Sections
                  </div>
                  <v-divider></v-divider>
                  <v-list
                    style="height: 70vh; overflow: auto; position: relative"
                  >
                    <div
                      class="container list-wrapper"
                      style="cursor: pointer"
                      @click="getUserBySection(section)"
                      :class="{
                        active:
                          selectedSectionData &&
                          selectedSectionData._id == section._id,
                      }"
                      v-for="(section, i) in temp_section"
                      :key="i"
                    >
                      {{ section.name }}
                    </div>
                  </v-list>
                  <div
                    v-if="temp_section && temp_section.length == 0"
                    class="no-data"
                  >
                    No Section
                  </div>
                </v-container>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>

        <!-- user list -->
        <v-flex sm12 md4>
          <v-card class="mx-auto ma-5 pull-left" max-width="95%" outlined dark>
            <v-container>
              <v-row class="right-wrapper">
                <v-container>
                  <div
                    style="
                      min-height: 30px;
                      text-align: center;
                      font-weight: 700;
                    "
                  >
                    Users
                  </div>
                  <v-divider></v-divider>
                  <v-list
                    style="height: 70vh; overflow: auto; position: relative"
                  >
                    <div
                      class="container list-wrapper"
                      style="cursor: pointer"
                      v-for="(user, i) in temp_users"
                      :key="i"
                    >
                      {{ user ? user.email : "-" }}
                    </div>
                  </v-list>
                  <div
                    v-if="temp_users && temp_users.length == 0"
                    class="no-data"
                  >
                    No Users
                  </div>
                </v-container>
              </v-row>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import Mixins from "@/mixin";
import AdminAPI from "@/services/admin_api";
import Multiselect from "vue-multiselect";
import FullLoader from "../components/full-loader.vue";

export default {
  name: "project-management",
  mixins: [Mixins],
  components: {
    Multiselect,
    "full-loader": FullLoader,
  },
  data() {
    return {
      // breadcrumb
      breadcrumbItems: [
        {
          text: "Project Management",
          disabled: false,
          to: { name: "project-management" },
          exact: true,
        },
      ],
      usersList: [],
      temp_zones: [],
      temp_section: [],
      temp_users: [],
      loading: false,
      selectedZoneData: null,
      selectedSectionData: null,
      search_key: "",
    };
  },
  computed: {},
  methods: {
    // filter user list by all levels and search key
    search() {
      if (this.search_key == "" || !this.search_key) {
        this.temp_zones = this.zones;
      } else {
        this.temp_zones = this.zones.filter((x) =>
          x.name.toLowerCase().match(this.search_key.toLowerCase())
        );
      }
    },
    async getAllUsers() {
      this.loading = true;
      let users = await AdminAPI.get(`/listUsersVthSections`);
      this.usersList = users.data;
      this.loading = false;
    },
    getSectionByZone(zone) {
      this.selectedZoneData = zone;
      this.selectedSectionData = null;
      this.temp_users = [];
      if (zone.sections) this.temp_section = zone.sections;
      this.temp_users = this.usersList.filter((x) => {
        if (
          x.access_levels.zones.length == 0 &&
          (x.role == "Admin" ||
            x.role == "Data Processing" ||
            x.role == "Super Admin")
        ) {
          return true;
        } else if (x.access_levels.zones.length > 0) {
          for (const iterator of x.access_levels.zones) {
            if (iterator._id == this.selectedZoneData._id) {
              return true;
            }
          }
        } else {
          return false;
        }
      });
     
    },
    getUserBySection2(section) {
      this.temp_users = [];
      this.selectedSectionData = section;
      this.temp_users = this.usersList.filter((x) => {
        if (
          x.access_levels.sections.length == 0 &&
          (x.role == "Admin" ||
            x.role == "Data Processing" ||
            x.role == "Super Admin")
        ) {
          return true;
        } else if (x.access_levels.sections.length > 0) {
          for (const iterator of x.access_levels.sections) {
            if (iterator._id == this.selectedSectionData._id) {
              return true;
            }
          }
        } else {
          return false;
        }
      });
    },
     getUserBySection(section) {
      this.selectedSectionData = section;
      this.temp_users = [];
      let tmpUserList = [];
      tmpUserList = this.usersList;
      tmpUserList.map((x) => {
       if (x.access_levels.zones.length > 0) {
          for (const iterator of x.access_levels.zones) {
         
             if (iterator.sections.length > 0) {
                iterator.sections.filter((element)=>{
                    if (element._id == this.selectedSectionData._id) {
                       this.temp_users.push(x);
                       return true;
                      }
               })
             }else{
               return false
             }
          }
        } else {
          return false;
        }
      });
      //  console.log("slected section user count -->",this.temp_users.length);
    },
  },
  async mounted() {
    //get zones
    this.loading = true;
    let zoneList = await AdminAPI.get(`/zonesVthSections`);
    this.zones = zoneList.data;
    this.temp_zones = this.zones;
    await this.getAllUsers();
    this.getSectionByZone(this.temp_zones[0]);
    this.loading = false;
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.no-data {
  position: absolute;
  top: 45%;
  left: 45%;
}
.list-wrapper:hover {
  background: rgb(255 255 255 / 31%);
}
.list-wrapper {
  transition: 0.3s;
  border-bottom: 1px solid #ffffffab;
  color: #fff;
}
.active {
  background: #fff;
  color: #000;
}
.sub-head {
  font-weight: 900;
  margin: 0px 10px;
}
.right-wrapper {
  height: 78vh;
  overflow-y: auto;
}
.wrapper {
  height: 80vh !important;
}
.header {
  display: flex;
  justify-content: space-between;
}
.pull-left {
  margin-left: 0 !important;
}
</style>
